import { IonPopover } from "@ionic/react";
import { useState } from "react";
import HeroButton from "../HeroButton";
import { ReactComponent as MaxwellLogo } from '../../icons/logo-45x45.svg';

function MaxChatBot() {
    const [showPopover, setShowPopover] = useState<{open: boolean, event: Event | undefined}>(
      {open: false, event: undefined}
    );

    const customEmbeddedChatbotHtml = 
      `<script async type=‘module’ src=‘https://interfaces.zapier.com/assets/web-components/zapier-interfaces/zapier-interfaces.esm.js’>
      </script>
      <zapier-interfaces-chatbot-embed is-popup=‘false’ chatbot-id=‘cm2qi4mta0012127s2p7mx9o2’ height=‘600px’ width=‘400px’>
      </zapier-interfaces-chatbot-embed>`;

    return (
      <>
        <IonPopover
          isOpen={showPopover.open}
          event={showPopover.event}
          onDidDismiss={(e) => setShowPopover({open: false, event: undefined})}
          style={{
            width: 405
          }}
        >
          <div dangerouslySetInnerHTML={{__html: customEmbeddedChatbotHtml}}>
            {/* Placeholder for the chatbot */}
          </div>
          <iframe
            src="https://interfaces.zapier.com/embed/chatbot/cm2qi4mta0012127s2p7mx9o2"
            height="400px"
            width="400px"
            allow="clipboard-write *"
          >
          </iframe>
        </IonPopover>
        <HeroButton 
          title='Ask Max'
          textPadding={0}
          clickHandler={(e: { nativeEvent: Event; }) => setShowPopover({open: true, event: e.nativeEvent})}>
            <div
             style={{
              marginRight: 'auto',
              marginLeft: 'auto'
             }}>
              <MaxwellLogo/>
            </div>
        </HeroButton>
      </>
    );
};

export default MaxChatBot;