import axios from 'axios';

const APIURL = process.env.REACT_APP_API_HOST;

interface FeatureFlag {
    id: number;
    name: string;
    show: boolean;
    beta: boolean;
  }

const FeatureFlagService = {
    async featureFlags(): Promise<FeatureFlag[]> {
        return axios.get<FeatureFlag[]>(`${APIURL}/Util/FeatureFlags`).then((response) => {
            return response.data;
        });
    },
    async featureAvailable(name: string) {
        const ffs = await this.featureFlags();
        return ffs.some((ff) => ff.name == name && ff.show);
    },
    async featuresAvailable(): Promise<FeatureFlag[]> {
        const ffs = await this.featureFlags();
        return ffs.filter((ff) => ff.show === true);
    },
};

export default FeatureFlagService;
