import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';

import HeadSubpage from '../Nav/HeaderSubpage';
import FavoriteService from '../Shared/services/FavoriteService';
import MemberService, { memberState } from '../Shared/services/MemberService';
import PartnerService, {
  favoritesPartnersState,
  partnersState,
} from '../Shared/services/PartnerService';
import '../Shared/theme/style.css';
import FeatureFlagService from '../Shared/services/FeatureFlagService';
import PartnerFavorites from './PartnerFavorites';
import PartnerFeaturedItems from './PartnerFeaturedItems';
import PartnerHero from './PartnerHero';
import PartnerList from './PartnerList';

const Partner: React.FC = () => {
  const [member, _member] = useRecoilState<any>(memberState);
  const [showList, _showList] = useState<boolean>(true);
  const [partners, _partners] = useRecoilState<any>(partnersState);
  const [favorites, _favorites] = useRecoilState<any>(favoritesPartnersState);
  const [productsWeLove, setProductsWeLove] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    FeatureFlagService.featureAvailable('Products We Love')
                      .then((res) => {
                        setProductsWeLove(res);
                      });

    if (!member) {
      MemberService.getMember().then((res) => {
        _member(res.data);
      });
    }

    PartnerService.getPartners().then((res) => {
      _partners(res.data);
    });
  }, [history]);

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className='main-content'>
        {member && partners && (
          <div className='main-container'>
            <PartnerHero />
            {productsWeLove ? (
              <PartnerFeaturedItems />
            ) : (
              <PartnerFavorites
                favorited={() => {
                  setTimeout(() => {
                    PartnerService.getPartners().then((res) => {
                      _partners(res.data);
                    });

                    FavoriteService.getFavorites('Partner').then((res) => {
                      _favorites(res.data);
                    });
                  }, 1000);
                }}
                partners={partners.filter((x: any) => favorites.favIds.includes(x.id))}
              />
            )}
            {showList && <PartnerList partners={partners} />}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Partner;
