import { IonCol, IonGrid, IonPopover, IonRow } from '@ionic/react';
import { useState } from 'react';
import { useHistory } from 'react-router';

import '../Shared/theme/style.css';
import HeroButton from '../components/HeroButton';

export default function RegalosHero() {
  const [showPopover, _showPopover] = useState(false);

  const history = useHistory<any>();

  function GetHeroButtonIconNode(iconClass: string){
    return (
        <div
          style={{
            width: 44,
            height: 44,
            border: '1px solid black',
            boxShadow: '1px 2px 5px 0px rgba(0,0,0,0.50)',
            borderRadius: 10,
            marginLeft: 'auto',
            marginRight: 'auto',
            fontSize: 20,
            paddingTop: 9,
          }}
        >
          <i className={iconClass}>
          </i>
        </div>
    )
  }

  return (
    <div className=''>
      <IonGrid class='ion-no-padding'>
        <IonRow>
          <IonCol size='6'>
            <div
              style={{
                fontWeight: 500,
                fontSize: 24,
                padding: '22px 0px 0px 22px',
                display: 'inline-block',
              }}
            >
              Regalos
            </div>
            <div
              id='click-trigger'
              style={{
                display: 'inline-block',
                verticalAlign: 'top',
                paddingTop: 16,
              }}
            >
              <div
                onClick={() => {
                  _showPopover(true);
                }}
                id='open-popover'
              >
                <i className='icon bi-exclamation-circle'></i>
              </div>

              <IonPopover
                onDidDismiss={() => {
                  _showPopover(false);
                }}
                isOpen={showPopover}
                side='bottom'
                className='mw-popover'
                trigger='open-popover'
              >
                <div
                  style={{ width: 50 }}
                  onClick={() => {
                    _showPopover(false);
                  }}
                >
                  <i className='icon bi-x-square'></i>
                </div>
                <div style={{ padding: 8 }}>
                  <div>
                    Regalos, meaning gifts in Spanish, is where you can send funds with messages to
                    your team. Say ‘Good job’, celebrate a birthday, or make someone’s day by
                    sending a Regalos.
                    <br />
                    <br />
                    Did you receive some Regalos? You can use these funds in-app or transfer over to
                    your Maxwell Card whenever you would like. The plus side of Regalos is that they
                    never expire — even at the end of the year.
                    <br />
                  </div>
                </div>
              </IonPopover>
            </div>
          </IonCol>
          <IonCol>
            <div
              style={{
                width: '100%',
                textAlign: 'right',
                paddingRight: 15,
                paddingTop: 15,
                fontSize: 24,
                fontWeight: 500,
              }}
            >
              {/* {formatter.format(props.amount * 0.01)} */}
            </div>
          </IonCol>
        </IonRow>
        <div
          style={{
            height: 110,
            display: 'flex',
            paddingLeft: 20,
            paddingTop: 6,
          }}
        >
          <HeroButton 
            title='Send Regalos' 
            clickHandler={() => history.push('/regalos_send')}>
              {GetHeroButtonIconNode('icon bi-heart-half')}
          </HeroButton>
          <HeroButton 
            title='Transfer' 
            clickHandler={() => history.push('/regalos_transfer')}>
              {GetHeroButtonIconNode('icon bi-shuffle')}
          </HeroButton>
          <HeroButton 
            title='View Team'
            clickHandler={() => history.push('/regalos_send')}>
              {GetHeroButtonIconNode('icon bi-people-fill')}
          </HeroButton>
        </div>
      </IonGrid>
    </div>
  );
}
