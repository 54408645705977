function HeroButton(options: { children: React.ReactNode | undefined; title: string; textPadding?: number; clickHandler: any }) {
    return (
      <div
        style={{
          width: 80,
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
        onClick={options.clickHandler}
      >
        { options.children }
        <div
          style={{
            fontSize: 10,
            fontWeight: 500,
            paddingTop: options.textPadding ?? 8,
          }}
        >
          {options.title}
        </div>
      </div>
    );
}

export default HeroButton;